
$(document).ready(function() {
function countdownTimer() {
    var timerElement = document.getElementById("timer");
    var time = timerElement.innerHTML.split(":");
    var minutes = parseInt(time[0]);
    var seconds = parseInt(time[1]);

    var timer = setInterval(function() {
        if (seconds === 0) {
            if (minutes === 0) {
                // Restart the timer
                minutes = 10;
                seconds = 0;
            } else {
                minutes--;
                seconds = 59;
            }
        } else {
            seconds--;
        }

        timerElement.innerHTML = ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
    }, 1000);
}

countdownTimer();
console.log("Shogun Head Loaded");
});